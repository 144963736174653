.field-container {
    margin: 4px 0;
    display: flex;
}

.field-title {
    color: #2900e2;
    font-weight: bold;
    width: 15%;
}

.field-value {
    width: 85%;
    overflow-wrap: break-word;
}
