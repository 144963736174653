.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.header {
    height: 70px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}

.fields {
    padding: 10px;
    border-style: solid;
}
